<template>
  <div>
    <v-card :loading="loading" v-if="loading">...</v-card>
    <v-card :loading="loading" v-if="classe"> 
      <v-list two-line>
        <v-list-item>
          <v-list-item-icon>
            <v-icon color="indigo">mdi-layers</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{classe.level.name}}</v-list-item-title>
            <v-list-item-subtitle></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider inset></v-divider>
        <v-list-item>
          <v-list-item-icon>
            <v-icon color="indigo">mdi-calendar-clock</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{classe.schedule.day}}</v-list-item-title>
            <v-list-item-subtitle>{{classe.schedule.startTime}} - {{classe.schedule.endTime}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider inset></v-divider>
        <v-list-item>
          <v-list-item-icon>
            <v-icon color="indigo">mdi-door</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{classe.classroom.name}}</v-list-item-title>
            <v-list-item-subtitle></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider inset></v-divider>
        <v-list-item>
          <v-list-item-icon>
            <v-icon color="indigo">mdi-school</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{classe.teacher.name}}</v-list-item-title>
            <v-list-item-subtitle>{{$t('teacher')}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-data-table :headers="headers" :items="classe.inscriptions" :items-per-page="50" class="elevation-1">
        <template v-slot:item.student.birth_date="{ item }">{{getAge(item.student.birth_date)}}</template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { APIServices } from "@/api/api";
export default {
  data() {
    return {
      classe: null,
      headers: [
        { text: this.$t('lastname'), align: "start", value: "student.last_name" },
        { text: this.$t('firstname'), value: "student.first_name" },
        { text: this.$t('age'), value: "student.birth_date" }
      ],
      loading: false
    };
  },
  created() {
    this.loading = true;
    this.loadClasse();
  },
  methods: {
    getAge(ddn) {
      return this.$moment(ddn, "YYYY-MM-DD").fromNow(true);
    },
    loadClasse() {
      this.loading = true;
      APIServices.get("/classes/" + this.$route.params.id)
        .then(response => {
          console.log(response);
          this.classe = response.data;
          this.loading = false;
        })
        .catch(e => {
          this.error = e.response.data;
          console.log(e.response.data);
          this.loading = false;
        });
    }
  }
};
</script>

<style></style>
